export default function Button({
  onClick,
  children,
  likedQuotes,
  id,
  disable,
}: any) {
  const handleBtnClick = () => {
    onClick();
  };
  return (
    <button className="button_" onClick={handleBtnClick} disabled={disable}>
      {children}
    </button>
  );
}
