// import data from "../../data/category.json";

import { useEffect, useState } from "react";
import StartScreen from "../startScreen/startScreen";
import "./categories.scss";
import { NavLink } from "react-router-dom";
import JsonData from "../../data/data.json";
import Nav from "../../nav/nav";
import Button from "../../button/button";

const getLocal = () => {
  const local = localStorage.getItem("startScreen");
  if (local && local !== "false") {
    return true;
  } else {
    return false;
  }
};

export default function Categories() {
  const [startScreen, setStartScreen] = useState<boolean>(getLocal());
  const [data, setData] = useState(JsonData);
  const CheckIsHidden = (e: boolean) => {
    setStartScreen(e);
  };

  return (
    <div className={`container categories_container`}>
      {startScreen && <StartScreen isHiden={CheckIsHidden} />}
      <Nav title="Topics" />
      <ul className="list">
        {Object.entries(data).map(([category, { text, quotes, img }]: any) => (
          <li key={category} className="list_item">
            <NavLink
              to={`/qoutes/${category}/${quotes[0].id}`}
              className={"link"}
            >
              <div>
                <h2 className="title">
                  {category
                    .replace(/&/g, " and ")
                    .replace(/([A-Z])/g, " $1")
                    .trim()}
                </h2>
                <p className="descr">{text}</p>
              </div>
              <img src={img} alt="" className="image" />
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
