import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./quotes.scss";
import data from "../../data/data.json";
import Button from "../../button/button";
import SvgImport from "../../svgItems/svgImport";
import Modal from "../../modal/modal";

interface Quote {
  id: string;
  text: string;
}

interface Category {
  text: string;
  img: string;
  quotesImg: string;
  quotes: Quote[];
}

interface Data {
  [key: string]: Category;
}

const typedData: Data = data as Data;

export default function QuotesPage() {
  const { category, id } = useParams<{ category?: string; id?: string }>();
  const [activeQuote, setActiveQuote] = useState<Quote | undefined>();
  const [isLastQuote, setIsLastQuote] = useState(false);
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  const numericId = parseInt(id ?? "1", 10);

  const [isFirstQuote, setIsFirstQuote] = useState(false);

  useEffect(() => {
    if (category && id) {
      const numericId = parseInt(id, 10);
      const categoryData = typedData[category];
      if (categoryData) {
        const quotesIds = categoryData.quotes.map((quote) =>
          parseInt(quote.id, 10)
        );
        const lastQuoteId = Math.max(...quotesIds);
        const firstQuoteId = Math.min(...quotesIds);
        setIsLastQuote(numericId >= lastQuoteId);
        setIsFirstQuote(numericId <= firstQuoteId);
        const findQuoteById = categoryData.quotes.find(
          (quote) => parseInt(quote.id, 10) === numericId
        );
        setActiveQuote(findQuoteById);
      }
    }
  }, [category, id]);

  const ifAddToFavourite = (id: any) => {
    interface Local {
      id?: string;
      category: string;
    }

    const localFavourites = localStorage.getItem("favourites");
    try {
      if (id && localFavourites) {
        const parseLocal = JSON.parse(localFavourites);
        const checkIfAdd = parseLocal.some(
          (element: Local) => element.id == id
        );
        console.log(checkIfAdd);
        return checkIfAdd;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AddToFavorite = (id: any, category: any) => {
    const localFavourites = localStorage.getItem("favourites");

    if (id && !ifAddToFavourite(id)) {
      try {
        const favouritesArray = localFavourites
          ? JSON.parse(localFavourites)
          : [];

        if (!favouritesArray.includes(id)) {
          favouritesArray.push({ id: id, category: category });

          localStorage.setItem("favourites", JSON.stringify(favouritesArray));
        }
      } catch (err) {
        console.error("Error updating favorites:", err);
      }
    }
  };

  const nextPage = () => {
    if (!isLastQuote && activeQuote) {
      const numericId = parseInt(activeQuote.id, 10);
      navigate(`/qoutes/${category}/${numericId + 1}`);
    }
  };

  const prevPage = () => {
    if (!isFirstQuote && activeQuote) {
      const numericId = parseInt(activeQuote.id, 10);
      navigate(`/qoutes/${category}/${numericId - 1}`);
    }
  };

  const returnBtn = () => {
    navigate(`/`);
  };

  const toggleModaL = () => {
    setModal(!modal);
  };

  return (
    <div className={`container quotes_container`}>
      {modal && <Modal />}

      {activeQuote ? (
        <div className="quote">
          <div className="top_container">
            <Button onClick={returnBtn}>
              <SvgImport.KeyboardArrow />
            </Button>
            <img
              src={typedData[category || "WomenEmpowerment"].quotesImg}
              alt="Category"
              className="images"
            />
            <div className="custom_btn">
              <Button onClick={toggleModaL}>
                <span className="line"></span>
                <span className="next_line"></span>
              </Button>
            </div>
          </div>

          <div className="text_container">
            <h3 className="title">
              {category
                ?.replace(/&/g, " and ")
                .replace(/([A-Z])/g, " $1")
                .trim()}
            </h3>
            <p className="text">{activeQuote.text}</p>
          </div>
        </div>
      ) : (
        <p className="user_err_message">Quote not found</p>
      )}
      <div className="button_container">
        <Button onClick={prevPage}>
          <SvgImport.ArrLeft className="arow_icon" />
        </Button>

        <div className="add_to">
          <Button
            onClick={() => AddToFavorite(activeQuote?.id, category)}
            disable={ifAddToFavourite(activeQuote?.id)}
          >
            Add to favourite
          </Button>
        </div>

        <Button onClick={nextPage}>
          <SvgImport.ArrRigth className="arow_icon" />
        </Button>
      </div>
    </div>
  );
}
