import React, { useEffect, useState } from "react";
import "./components/scss/app.scss";
import Nav from "./components/nav/nav";
import QuotesPage from "./components/pages/quotes/quotes";
import Favourites from "./components/pages/favourites/favourites";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Categories from "./components/pages/categories/categories";
import Setings from "./components/pages/setings/setings";
import Policy from "./components/pages/policy/policy";
import Top from "./components/pages/top/top";
import SetTheme from "./components/pages/setings/colorSelector";
const localFavourites = localStorage.getItem("favourites");
const startScreen = localStorage.getItem("startScreen");
const localTheme = localStorage.getItem("themeName");

if (!localFavourites) {
  localStorage.setItem("favourites", "[]");
}
if (!startScreen) {
  localStorage.setItem("startScreen", "true");
}

if (localTheme) {
  SetTheme(localTheme);
}
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Categories />} />
        <Route path="/favourites" element={<Favourites />} />
        <Route path="/qoutes/:category/:id" element={<QuotesPage />} />
        <Route path="/setings" element={<Setings />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/top" element={<Top />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
