import { ReactComponent as ArrRigth } from "./ArowRight.svg";
import { ReactComponent as ArrLeft } from "./ArowLeft.svg";
import { ReactComponent as KeyboardArrow } from "./keyboard_arrow_left.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as Cog } from "./Cog.svg";




const SvgImport={
    ArrLeft,
    ArrRigth,
    KeyboardArrow,
    Share,
    Cog

}
export default SvgImport;


