import { NavLink } from "react-router-dom";
import "./modal.scss";

export default function Modal() {
  return (
    <div className="modal_wrap">
      <div className="modal_container">
        <NavLink className="link" to={"/"}>
          Topics
        </NavLink>
        <NavLink className="link" to={"/favourites"}>
          Favourites
        </NavLink>
        <NavLink className="link" to={"/top"}>
          TOP
        </NavLink>
        <NavLink className="link" to={"/setings"}>
          Settings
        </NavLink>
      </div>
    </div>
  );
}
