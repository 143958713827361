import { useState, useEffect } from "react";
import rawData from "../../data/data.json";
import "./favourites.scss";
import Nav from "../../nav/nav";
import Button from "../../button/button";
import SvgImport from "../../svgItems/svgImport";
import { NavLink } from "react-router-dom";

interface IQuote {
  id: string;
  text: string;
}

interface ICategory {
  quotes: IQuote[];
  // Інші поля, якщо є
}

interface IData {
  [category: string]: ICategory;
}

const typedData: IData = rawData as IData;

export default function Favourites() {
  const [category, setCategory] = useState("");

  const getQuoteByIndex = (index: number): IQuote | undefined => {
    const favorites = JSON.parse(localStorage.getItem("favourites") || "[]");
    if (favorites.length > 0) {
      const { id, category } = favorites[index];
      setCategory(category);
      return typedData[category]?.quotes.find((item: IQuote) => item.id === id);
    } else {
      console.log("");
    }
  };

  const [currentQuote, setCurrentQuote] = useState<IQuote | undefined>();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const quote = getQuoteByIndex(0);
    setCurrentQuote(quote);
  }, []);

  const nextQuote = () => {
    const favorites = JSON.parse(localStorage.getItem("favourites") || "[]");
    if (currentIndex < favorites.length - 1) {
      const nextQuote = getQuoteByIndex(currentIndex + 1);
      if (nextQuote) {
        setCurrentIndex(currentIndex + 1);
        setCurrentQuote(nextQuote);
      }
    }
  };

  const prevQuote = () => {
    const favorites = JSON.parse(localStorage.getItem("favourites") || "[]");
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      const prevQuote = getQuoteByIndex(newIndex);
      if (prevQuote) {
        setCurrentIndex(newIndex);
        setCurrentQuote(prevQuote);
      }
    }
  };

  return (
    <>
      {currentQuote && (
        <div className="container favourites_container">
          <Nav title="Favourites" />
          <div className="content">
            <div className="text_container">
              <h3 className="title">
                {category
                  ?.replace(/&/g, " and ")
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
              </h3>
              <p className="text">{currentQuote?.text}</p>
            </div>

            <div className="button_container">
              <Button onClick={prevQuote}>
                <SvgImport.ArrLeft className="arow_icon" />
              </Button>
              <Button onClick={nextQuote}>
                <SvgImport.ArrRigth className="arow_icon" />
              </Button>
            </div>
          </div>
        </div>
      )}
      {!currentQuote && (
        <div className="container favourites_container">
          <div className="empty">
            <p className="empty_text">
              There is nothing to show yet. Please review the quotes and add it
              via choosing{" "}
            </p>
            <NavLink to={"/"} className="empty_link">
              Choose topic
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
}
