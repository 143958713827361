import { useState } from "react";
import Button from "../../button/button";
import "./style.scss";

const screens = [
  {
    img: "/startScreenImg/Mask_group_1.png",
    text: "Explore daily quotes that uplift and motivate",
    buttonText: "Next",
  },
  {
    img: "/startScreenImg/Mask_group_2.png",
    text: "Unlock a reservoir of wisdom with each swipe",
    buttonText: "Next",
  },
  {
    img: "/startScreenImg/Mask_group_3.png",
    text: "Discover quotes tailored to your daily needs",
    buttonText: "Start",
  },
];

export default function StartScreen({ isHiden }: any) {
  const [currentScreen, setCurrentScreen] = useState(0);

  const handleBtnClick = () => {
    if (currentScreen < screens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      isHiden(false);
      localStorage.setItem("startScreen", "false");
    }
  };

  const viewScreen = screens[currentScreen];

  return (
    <div className="container start_screen_container">
      <div className="elipse_side"></div>
      <div className="elipse a"></div>
      <div className="elipse b"></div>
      <div className="elipse c"></div>
      <div className="elipse d"></div>
      <div className="elipse f"></div>
      <div className="elipse g"></div>

      {viewScreen && (
        <div className="content">
          <img
            src={viewScreen?.img}
            alt="start screen image"
            className="image"
          />
          <h2 className="title">{viewScreen.text}</h2>
          <Button onClick={handleBtnClick}>{viewScreen.buttonText}</Button>
        </div>
      )}
    </div>
  );
}
