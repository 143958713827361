import "./policy.scss";
export default function Policy() {
  return (
    <div className={`container policy_container`}>
      <h2 className="title"> title </h2>
      <p className="text">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic neque
        praesentium aperiam fugit voluptatum error iusto consequuntur, illum ad
        quos unde explicabo velit quam, laboriosam vel quisquam. Esse, illo
        voluptatum!
      </p>
    </div>
  );
}
