import { useEffect, useState } from "react";
import "./top.scss";
import rawData from "../../data/data.json";
import Nav from "../../nav/nav";
import Button from "../../button/button";
import SvgImport from "../../svgItems/svgImport";

interface IQuote {
  id: string;
  text: string;
  category?: string; // Опціональне поле для зберігання назви категорії
}

interface ICategory {
  quotes: IQuote[];
}

interface IData {
  [category: string]: ICategory;
}

const typedData: IData = rawData as IData;

const shuffleQuotes = (data: IData): IQuote[] => {
  let allQuotes: IQuote[] = [];
  Object.entries(data).forEach(([categoryName, category]) => {
    allQuotes = allQuotes.concat(
      category.quotes.map((quote) => ({ ...quote, category: categoryName }))
    );
  });

  // Перемішування масиву
  for (let i = allQuotes.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allQuotes[i], allQuotes[j]] = [allQuotes[j], allQuotes[i]];
  }

  return allQuotes.slice(0, 10); // Повернення перших 10 цитат
};

export default function Top() {
  const [quotes, setQuotes] = useState<IQuote[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const mixedQuotes = shuffleQuotes(typedData);
    setQuotes(mixedQuotes);
  }, []);

  const currentQuote = quotes[currentIndex];

  const nextQuote = (): void => {
    if (currentIndex < quotes.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const prevQuote = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(quotes.length - 1); // Перейти до останньої цитати, якщо на початку
    }
  };

  return (
    <div className="container top_quotes_container">
      <Nav title="Favourites" />
      {currentQuote && (
        <div className="content">
          <div className="text_container">
            <h3 className="title">
              {currentQuote.category
                ?.replace(/&/g, " and ")
                .replace(/([A-Z])/g, " $1")
                .trim()}
            </h3>
            <p className="text">{currentQuote?.text}</p>
          </div>

          <div className="button_container">
            <Button onClick={prevQuote}>
              <SvgImport.ArrLeft className="arow_icon" />
            </Button>
            <Button onClick={nextQuote}>
              <SvgImport.ArrRigth className="arow_icon" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
