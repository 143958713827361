import Button from "../button/button";
import SvgImport from "../svgItems/svgImport";
import "./nav.scss";
import { NavLink, useNavigate } from "react-router-dom";
interface Props {
  title?: string;
}
export default function Nav({ title }: Props) {
  console.log(title);
  const navigate = useNavigate();
  const redirectToSetings = () => {
    navigate(`/setings`);
  };

  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <div className={`container nav_container`}>
      <div className="head_container">
        <div className="invisible"></div>

        <div className="custom_btn">
          <Button onClick={redirectToHome}>
            <SvgImport.KeyboardArrow className="arrow" />
          </Button>
        </div>
        <div className="title_container">
          <h2 className="title">{title}</h2>
        </div>
        <Button onClick={redirectToSetings}>
          <SvgImport.Cog className="cog" />
        </Button>
      </div>

      <div className="nav_list">
        <div className="nav_link_container">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active" : "link")}
          >
            Topics
          </NavLink>
        </div>
        <div className="nav_link_container">
          <NavLink
            to="/favourites"
            className={({ isActive }) => (isActive ? "active" : "link")}
          >
            Favourites
          </NavLink>
        </div>

        <div className="nav_link_container">
          <NavLink
            to="/top"
            className={({ isActive }) => (isActive ? "active" : "link")}
          >
            Top
          </NavLink>
        </div>
      </div>
    </div>
  );
}
