import { NavLink, useNavigate } from "react-router-dom";
import "./setings.scss";
import Button from "../../button/button";
import { useEffect, useState } from "react";
import SvgImport from "../../svgItems/svgImport";
import SetTheme from "./colorSelector";

export default function Setings() {
  const defaultThemeIndex = 0; // Індекс теми за замовчуванням
  const themes = [
    "Women-Empowerment",
    "dark-blue",
    "purple",
    "aqua",
    "night-purple",
    "gold-dark",
  ];

  const [volume, setVolume] = useState(true);
  const [sound, setSound] = useState(true);
  const [themeIndex, setThemeIndex] = useState(() => {
    const savedThemeIndex = localStorage.getItem("themeIndex");
    return savedThemeIndex !== null
      ? parseInt(savedThemeIndex, 10)
      : defaultThemeIndex;
  });
  const [themeName, setThemeName] = useState(() => {
    const savedThemeName = localStorage.getItem("themeName");
    return savedThemeName || themes[0]; // Якщо немає збереженої теми, використовуємо першу тему з масиву
  });
  const navigate = useNavigate();

  const handleVolumeChange = () => {
    setVolume(!volume);
  };
  const contactUs = () => {
    console.log("contuct us function");
  };

  useEffect(() => {
    localStorage.setItem("themeName", themeName);
  }, [themeName]);

  const changeTheme = () => {
    const currentIndex = themes.indexOf(themeName);
    const nextThemeIndex =
      currentIndex < themes.length - 1 ? currentIndex + 1 : 0;
    setThemeName(themes[nextThemeIndex]);
    SetTheme(themes[nextThemeIndex]);
  };

  const handleReturn = () => {
    navigate("/");
  };
  const soundChange = () => {
    setSound(!sound);
  };

  const clearStorage = () => {
    localStorage.clear();
  };

  return (
    <div className={`container setings_container`}>
      <div className="setings_nav">
        <Button onClick={handleReturn}>
          <SvgImport.KeyboardArrow className="svg" />
        </Button>
        <div className="title_container">
          <h2 className="title">Settings</h2>
        </div>
      </div>
      <div className="setings_wrap">
        <div className="setings_params">
          <p className="text">Theme</p>

          <div onClick={changeTheme} className="theme_btn">
            <span className="lable desr">{themeName}</span>
            <SvgImport.KeyboardArrow className="svg_arrow" />
          </div>

          <div className="sound_btn">
            <span className="desr">Sounds</span>
            <label className="switch">
              <input type="checkbox" checked={sound} onChange={soundChange} />
              <span className="slider round"></span>
            </label>
          </div>
          <p className="text">More</p>
          <NavLink className="link" to={"/policy"}>
            <div className="theme_btn">
              <span className="lable desr">Privacy policy</span>
              <SvgImport.KeyboardArrow className="svg_arrow" />
            </div>
          </NavLink>

          <div onClick={contactUs} className="custom">
            <span className="lable desr">Contact us</span>
            <SvgImport.KeyboardArrow className="svg_arrow" />
          </div>
        </div>
        <Button onClick={clearStorage}>Reset data</Button>
      </div>
    </div>
  );
}
